import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';
import featuredImage from '../../../images/services/calgary-construction-surveying-img1.jpg';
import imageBuilding from '../../../images/construction/building-development.jpg';
import imageDevPermit from '../../../images/construction/development-permit.jpg';
import imageEngineering from '../../../images/construction/engineering-survey.jpg';
import imageStakeout from '../../../images/construction/house-stakeout-v2.jpg';
import imageLotGrading from '../../../images/construction/lot-grading.jpg';
import imageMainFloor from '../../../images/construction/main-floor.jpg';
import imagePlot from '../../../images/construction/plot-plan.jpg';
import imageRightOfWay from '../../../images/construction/right-of-way.jpg';
import imageStreetscape from '../../../images/construction/screetscape-v2.jpg';
import '../../../styles/media.css';

const ConstructionIndex = () => (
  <Layout
    title="Calgary Construction Surveying"
    description="Ensure your construction project runs smoothly with Axiom Geomatics. We offer expert construction surveying services in Calgary for all types of developments.
"
    keywords="land development, municipal services, building a home, building garage ,building warehouse, landscaping survey, topographical plans, as-built-plans, as-located plans, drainage plans, slope plans, gridlines, excavation, staking, grades, volumes"
  >
    <main>
      <h1>Construction Surveys</h1>

      <div className="text-center my-4">
        <img src={featuredImage} alt="" width={800} height={450} />
      </div>

      <p>
        Without exception, successful building projects begin with accurate measurements and careful
        planning. All Calgary construction businesses that we have worked with over the years rely
        on precise surveying to ensure compliance with municipal regulations, avoid errors, and keep
        projects on schedule.
      </p>
      <p>
        If you are developing a residential property, commercial structure, or a large-scale
        multi-million dollar infrastructure in Calgary, professional construction surveying is a
        prerequisite in laying the groundwork for the success of your project.
      </p>
      <p>
        Axiom Geomatics provides industry-leading construction surveying services designed for
        architects, engineers, developers, and contractors. Our wide range of services will aid with
        local zoning laws, property boundary regulations, and structural safety standards.
      </p>
      <h2>Why Construction Surveys Matter</h2>

      <p>
        Surveying is the jumping-off point for a project that is executed correctly from start to
        finish. Without proper construction surveying, structures may not meet their approved plans,
        commonly leading to dramatic setbacks and complicated, headache-inducing legal issues—not to
        mention an increase to your budget.
      </p>
      <p>Surveys also help in the following areas:</p>
      <ul>
        <li>Verify property lines and setbacks</li>

        <li>Prevent construction delays due to measurement discrepancies</li>

        <li>Ensure proper elevation levels for drainage and stability</li>

        <li>Identify existing infrastructure, such as utilities and roadways</li>

        <li>Support compliance with Calgary’s building codes</li>
      </ul>

      <h2>Axiom Geomatics for Calgary Construction Surveying</h2>

      <p>
        From development permit surveys to lot grading certificates, our highly skilled, expert team
        of surveyors delivers the precision and reliability you need for smooth project execution.
        With years of experience in construction services in Calgary, we provide accurate data to
        keep projects moving forward efficiently.
      </p>
      <p>
        Explore our range of professional construction surveying services below and learn how we can
        support your next project:
      </p>

      <div className="media-list">
        <Link to="/services/construction-services/development-permits">
          <img
            src={imageDevPermit}
            alt="Development Permit survey"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/development-permits">
            Development Permit Surveys
          </Link>
        </h2>
        <p>
          When building a new house, the initial step for many property owners, developers,
          designers, and architects is requesting a survey of the property and surrounding area.
          This survey will determine the nature and location of the structures that can be built on
          the property. It also verifies compliance with zoning laws and setbacks, so your project
          meets all Calgary construction requirements before even breaking ground.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/development-permits">
            Learn about Development Permit Surveys
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/construction-services/streetscapes">
          <img
            src={imageStreetscape}
            alt="Streetscape"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/streetscapes">Streetscapes</Link>
        </h2>
        <p>
          A Streetscape is the horizontal view of a Development Permit survey and is usually a
          required component in an application to the municipality for most property development
          work. This survey helps city planners and architects design streets that blend with the
          existing surroundings, maintaining local aesthetics and safety regulations.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/streetscapes">Learn about Streetscapes</Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/construction-services/plot-plans">
          <img
            src={imagePlot}
            alt="Plot Plan survey"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/plot-plans">Plot Plans</Link>
        </h2>
        <p>
          The Plot Plan displays the intended shape and dimensions of the new building in relation
          to the property boundaries. It forms the basis of all construction work. The plot plan
          must be approved by the municipal authority before construction commences. Accurate plot
          plans are needed to prevent boundary disputes and aid with construction surveying
          accuracy.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/plot-plans">Learn about Plot Plans</Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/construction-services/house-stakeouts">
          <img
            src={imageStakeout}
            alt="House stakeout service"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/house-stakeouts">House Stakeouts</Link>
        </h2>
        <p>
          A House Stakeout (or Excavation Survey or Basement Stakeout) is a service that marks the
          ground with construction stakes to identify the location of building construction and the
          depth of excavation for the basement. It ensures that you dig in the right place. This
          survey helps prevent expensive errors and helps with site plan alignment.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/house-stakeouts">
            Learn about House Stakeouts
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/construction-services/main-floor-and-footing-elevation-surveys">
          <img
            src={imageMainFloor}
            alt="Main Floor survey"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/main-floor-and-footing-elevation-surveys">
            Main Floor & Footing Elevation Surveys
          </Link>
        </h2>
        <p>
          A Main Floor Elevation Survey or Footing Elevation Survey ensures that the structure being
          built is of the correct height relative to municipal requirements. Depending upon
          municipal requirements, you may need either the Main Floor survey or the Footing Elevation
          survey. Confirming finished floor elevation at this stage prevents drainage issues and
          zoning violations.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/main-floor-and-footing-elevation-surveys">
            Learn about Main Floor & Footing Elevation Surveys
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/construction-services/lot-grading-certificates">
          <img
            src={imageLotGrading}
            alt="Lot Grading Certificate sample"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/lot-grading-certificates">
            Lot Grading Surveys & Certificates
          </Link>
        </h2>
        <p>
          A Lot Grading Survey is a type of municipal survey designed to determine which direction
          water will drain from the property and ensure that landscaping meets the Plot Plan
          specifications. The municipality may require a lot of grading certificates indicating that
          water drainage is appropriate. Proper lot grading ensures long-term drainage efficiency
          and prevents costly water damage.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/lot-grading-certificates">
            Learn about Lot Grading Surveys & Certificates
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/construction-services/utility-construction-surveys">
          <img
            src={imageRightOfWay}
            alt="Right-of-Way survey"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/utility-construction-surveys">
            Utility Construction Surveys
          </Link>
        </h2>
        <p>
          A Utility Construction Survey determines where utilities will be located on a property in
          the future. These utilities include fiber-optic lines, power, natural gas, and water and
          sewage lines. This survey prevents costly mistakes by ensuring utility placements align
          with infrastructure plans.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/utility-construction-surveys">
            Learn about Utility Construction Surveys
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/construction-services/large-building-construction">
          <img
            src={imageBuilding}
            alt="Large-scale building construction"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/large-building-construction">
            Large Building Construction
          </Link>
        </h2>
        <p>
          Large building construction involves multiple surveys to ensure that the commercial
          building, high-rise condominium, or parking lot structure projects are built as
          efficiently as possible. Precision in construction surveying is essential for avoiding
          compliance issues and ensuring structural stability.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/large-building-construction">
            Learn about Large Building Construction
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/construction-services/engineering-surveys">
          <img
            src={imageEngineering}
            alt="Engineering survey"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/engineering-surveys">Engineering Surveys</Link>
        </h2>
        <p>
          Axiom Geomatics leverages its survey expertise to support other engineering disciplines.
          This work includes volume calculations, slope stability surveys and more. Engineering
          surveys help optimize site design, ensuring projects meet Calgary construction standards.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/engineering-surveys">
            Learn about Engineering Surveys
          </Link>
        </p>
      </div>
    </main>
  </Layout>
);

export default ConstructionIndex;
